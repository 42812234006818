
import { defineComponent } from "vue";
import Utils from "@/utility/utils";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import Tag from "primevue/tag";
import Card from "primevue/card";
import Paginator from "primevue/paginator";

export default defineComponent({
  name: "ResultGridView",
  components: {
    Button,
    InputNumber,
    Tag,
    Card,
    Paginator
  },
  props: {
    productList: Array,
    rangeEnd: Number
  },
  emits: ["addPartToOrder", "openPartDetails", "nextRange"],
  mounted() {
      var grid = document.getElementById("grid") as Element;
      const gridComputedStyle = window.getComputedStyle(grid);
      // get number of grid columns
      const colCount = gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length;
      console.log(colCount);
      this.rows = colCount * 10
      this.pageOptions = [colCount * 10, colCount * 15, colCount * 20]
  },
  methods: {
    formatPrice(amount: number): string {
      return Utils.formatPrice(amount);
    },
    handleOnHandTagText(avail_qty: number): "In Stock" | "Out of Stock" {
      return avail_qty > 0 ? "In Stock" : "Out of Stock";
    },
    handleOnHandTagColor(avail_qty: number): "success" | "danger" {
      return avail_qty > 0 ? "success" : "danger";
    },
    handleOpenProduct(product: any): void {
      this.$emit("openPartDetails", { data: product });
    },
    scrollToFirstElement() {
      const grid = document.getElementById('grid');
      if (grid &&  grid.firstElementChild) {
        grid.firstElementChild.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onPage(event: any) {
      this.scrollToFirstElement();
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
          this.productList?.length == this.rangeEnd
      ) {
        this.$emit("nextRange")
      }
    },
    calculateStep(part: any) {
      return Utils.calculateQtyStep(part);
    }
  },
  data() {
    return {
      first: 0,
      rows: 25,
      pageOptions: [25, 50, 75]
    }
  },
});
